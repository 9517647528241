import Loading from '@components/loading';
import VideoPlayer from '@components/video-player';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import {
  fetchDownloadUrl,
  fetchPlayableUrl,
  fetchRecording,
  updateMarPlayableUrl,
  updateRecording as updateRecordingAction,
} from '@features/recordings-backup/redux/slices/recordings/recordings-slice';
import { selectTranscriptIsShowing } from '@features/recordings-backup/redux/slices/transcript/transcript-selectors';
import { setIsShowing as setIsTranscriptShowing } from '@features/recordings-backup/redux/slices/transcript/transcript-slice';
import { withSlicesProvider } from '@features/recordings-backup/slices/with-provider';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { Flex } from '@hopin-team/ui-flex';
import { Linkicon } from '@hopin-team/ui-linkicon';
import { IndeterminateProgressOverlay } from '@hopin-team/ui-recording-card';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPlayableUrl, getRecording } from '../api';
import EditRecordingModal from '../edit-recording/edit-recording-modal';
import {
  useDeleteRecording,
  useRevertTrimRecording,
  useTrimRecording,
  useUpdateRecording,
} from '../hooks';
import { withAuthProvider } from '../redux/slices/auth/with-auth';
import { selectEvent } from '../redux/slices/event/event-selectors';
import { withEventProvider } from '../redux/slices/event/with-event';
import {
  getOriginalRecordingById,
  getRecordingById,
  getRecordingPlayableUrl,
  selectRecordingIsLoaded,
} from '../redux/slices/recordings/recordings-selectors';
import { selectExternalRegistrationId } from '../redux/slices/registration/registration-selector';
import { withRegistrationProvider } from '../redux/slices/registration/with-registration';
import { withUserProvider } from '../redux/slices/user/with-user';
import { withReduxProvider } from '../redux/with-provider';
import { ThumbnailSidePanelModal } from '../thumbnail-side-panel-modal';
import TileView from '../tile-view';
import {
  getGoBackLink,
  hasRecordingFailed,
  hasRecordingSucceededOnTrimming,
  reportRecordingDownloadToAnalytics,
  reportRecordingPublishStatusUpdated,
} from '../utils/utils';
import {
  MainColumn,
  RelatedRecordingContainer,
  Skeleton,
} from './detailed-page.styled';
import { Message } from './partials/message';
import PublishControls from './partials/publish-controls';
import { RecordingControls } from './partials/recording-controls';
import { SidebarContainer } from './partials/sidebar/sidebar-container';
import { Transcript } from './partials/transcript';

const NUMBER_OF_RELATED_RECORDINGS_TO_SHOW = 4;

export const DetailedPageComponent = ({
  id,
  customThumbnailsEnabled,
  transcriptsEnabled,
  useMts,
}) => {
  const dispatch = useDispatch();
  const { t, language } = useContext(LocalizationContext);
  const { addAlert } = useContext(AlertsContext);
  const { eventId, slug } = useSelector(selectEvent);

  // State
  const [relatedRecordings, setRelatedRecordings] = useState();
  const [isEditUiOpen, setIsEditUiOpen] = useState(false);
  const [isThumbnailUiOpen, setIsThumbnailUiOpen] = useState(false);
  const [isTrimmingUiOpen, setIsTrimmingUiOpen] = useState(false);
  const [isDeleteUiOpen, setIsDeleteUiOpen] = useState(false);

  const [
    isHiddenTrimmingSuccessMessage,
    setIsHiddenTrimmingSuccessMessage,
  ] = useState(false);

  // Selectors
  const registrationExternalId = useSelector(selectExternalRegistrationId);
  const recording = useSelector(state => getRecordingById(state, id, language));
  const originalRecording = useSelector(state =>
    getOriginalRecordingById(state, id),
  );
  const playableUrl = useSelector(state => getRecordingPlayableUrl(state, id));
  const isTranscriptShowing = useSelector(selectTranscriptIsShowing);
  const recordingIsLoaded = useSelector(state =>
    selectRecordingIsLoaded(state, id, language),
  );

  const isRecordingSuccessfullyTrimmed = useMemo(
    () =>
      recordingIsLoaded ? hasRecordingSucceededOnTrimming(recording) : false,
    [recording, recordingIsLoaded],
  );
  const isPlayerLoading = useMemo(
    () => !isRecordingSuccessfullyTrimmed && !recording?.mar?.playable.isDone,
    [isRecordingSuccessfullyTrimmed, recording],
  );
  const recordingIdToFetchAssets = recording?.recordingIdToFetchAssets;

  useEffect(() => {
    if (!recordingIsLoaded && slug) {
      dispatch(fetchRecording({ id, slug }));
    }
  }, [dispatch, id, slug, recordingIsLoaded]);

  useEffect(() => {
    if (recordingIsLoaded && !isRecordingSuccessfullyTrimmed) {
      dispatch(fetchPlayableUrl(recordingIdToFetchAssets));
      dispatch(fetchDownloadUrl(recordingIdToFetchAssets));
    }
  }, [
    dispatch,
    recordingIdToFetchAssets,
    recordingIsLoaded,
    isRecordingSuccessfullyTrimmed,
  ]);

  useEffect(() => {
    const trimmedRelatedRecordings = recording?.relatedRecordings?.slice(
      0,
      NUMBER_OF_RELATED_RECORDINGS_TO_SHOW,
    );
    setRelatedRecordings(trimmedRelatedRecordings || []);
  }, [recording?.relatedRecordings]);

  // Trim recording hook
  const { trimRecording, isTrimSaving } = useTrimRecording({
    slug,
    onError: ({ message }) =>
      addAlert({ active: true, text: message, pattern: 'error' }),
  });

  // Revert Trimming Hook
  const { revertTrimRecording, isRevertSaving } = useRevertTrimRecording({
    slug,
    onError: ({ message }) =>
      addAlert({ active: true, text: message, pattern: 'error' }),
  });

  // Update Recording Hook
  const handleUpdateRecordingSuccess = useCallback(
    ({ updatedRecording }) => {
      if (updatedRecording.published !== originalRecording.published) {
        reportRecordingPublishStatusUpdated(originalRecording, {
          userId: registrationExternalId,
          eventId,
          isPublished: updatedRecording.published,
        });
      }
    },
    [eventId, originalRecording, registrationExternalId],
  );

  const { updateRecording } = useUpdateRecording({
    slug,
    onSuccess: handleUpdateRecordingSuccess,
    onError: ({ message: text }) =>
      addAlert({ active: true, text, pattern: 'error' }),
  });

  const handleOnRecordingChange = attributes =>
    updateRecording(recording, attributes);

  const onRecordingClick = useCallback(
    recording =>
      (window.location.href = Routes.organisersEventRecordingPath(
        slug,
        recording.id,
      )),
    [slug],
  );

  const handleReportDownloadToAnalyticsWithArg = useCallback(
    (recording, props) =>
      reportRecordingDownloadToAnalytics(recording, {
        ...props,
        userId: registrationExternalId,
        eventId,
      }),
    [registrationExternalId, eventId],
  );

  const handleReportDownloadFromButton = () =>
    handleReportDownloadToAnalyticsWithArg(originalRecording, {
      method: 'Recording',
    });

  const handleReportDownloadFromRelated = recording =>
    handleReportDownloadToAnalyticsWithArg(recording, {
      method: 'Suggested',
    });

  const redirectToMain = useCallback(() => {
    window.location.href = Routes.organisersEventRecordingsPath(slug);
  }, [slug]);

  const showConfirmationPopup = useCallback(() => setIsDeleteUiOpen(true), [
    setIsDeleteUiOpen,
  ]);

  // Delete Recording
  const handleDeleteRecordingSuccess = useCallback(() => {
    addAlert({
      active: true,
      text: t('recording-library.recording-successfully-deleted'),
      pattern: 'success',
    });
    setTimeout(redirectToMain, 1000);
  }, [addAlert, redirectToMain, t]);

  const handleDeleteRecordingError = useCallback(
    ({ message: text }) => {
      addAlert({ active: true, text, pattern: 'error' });
    },
    [addAlert],
  );

  const { deleteRecording } = useDeleteRecording({
    slug,
    onSuccess: handleDeleteRecordingSuccess,
    onError: handleDeleteRecordingError,
  });

  const deleteRecordingHandler = useCallback(() => {
    setIsDeleteUiOpen(false);
    deleteRecording(recording.id);
  }, [deleteRecording, recording?.id]);

  const onSidebarUpdateSuccess = useCallback(() => {
    addAlert({
      active: true,
      text: t('recording-library.recording-details-were-updated-successfully'),
      pattern: 'success',
    });
    setIsEditUiOpen(false);
  }, [addAlert, t]);

  const onSidebarUpdateError = useCallback(
    message => {
      addAlert({ active: true, text: message, pattern: 'error' });
      setIsEditUiOpen(false);
    },
    [addAlert],
  );

  const onViewTranscript = useCallback(() => {
    dispatch(setIsTranscriptShowing(!isTranscriptShowing));
  }, [dispatch, isTranscriptShowing]);

  const onCloseTranscript = useCallback(() => {
    dispatch(setIsTranscriptShowing(false));
  }, [dispatch]);

  const reloadSource = () => {
    return recording.mar.playable.url
      ? reloadMarPlayableUrl()
      : reloadRecordingSource();
  };

  const reloadRecordingSource = async () => {
    const newRecording = await getRecording(recording.id, slug);
    dispatch(updateRecordingAction({ playableUrl: newRecording.playableUrl }));
    return newRecording.playableUrl;
  };

  const reloadMarPlayableUrl = async () => {
    const response = await getPlayableUrl(recording.id);
    dispatch(
      updateMarPlayableUrl({
        id: recording.id,
        playableUrl: response.playableUrl,
      }),
    );
    return response.playableUrl;
  };

  return (
    <Flex flexDirection="column">
      <Alerts />
      <Loading isLoading={!recordingIsLoaded}>
        {recordingIsLoaded ? (
          <>
            <Flex flexDirection="column">
              {hasRecordingSucceededOnTrimming(recording) && (
                <Message
                  type="success"
                  hidden={isHiddenTrimmingSuccessMessage}
                  onClose={() => setIsHiddenTrimmingSuccessMessage(true)}
                />
              )}

              {hasRecordingFailed(recording) && <Message type="error" />}

              <Flex flexDirection="row" mb={2}>
                <MainColumn>
                  <Linkicon
                    data-testid="backLink"
                    iconScale={1.5}
                    scale={4}
                    label={t('recording-library.back')}
                    leadingIcon="arrow-back"
                    href={getGoBackLink(slug, recording)}
                    mb="2"
                    mt="4"
                  />

                  <Loading isLoading={isPlayerLoading}>
                    <VideoPlayer
                      src={playableUrl}
                      captionsSrc={recording?.captionsUrl}
                      forcePause={isTrimmingUiOpen || recording.isProcessing}
                      reloadSource={reloadSource}
                    >
                      {recording.isProcessing ? (
                        <IndeterminateProgressOverlay
                          data-testid="processing-overlay"
                          aria-valuetext={t('recording-library.processing')}
                        />
                      ) : null}
                    </VideoPlayer>
                  </Loading>
                </MainColumn>

                {transcriptsEnabled && isTranscriptShowing && (
                  <Transcript onClose={onCloseTranscript} />
                )}
              </Flex>
              <Flex
                flexGrow={1}
                my={2}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <RecordingControls
                  customThumbnailsEnabled={customThumbnailsEnabled}
                  onChangeThumbnail={() => setIsThumbnailUiOpen(true)}
                  onDelete={showConfirmationPopup}
                  onDownload={handleReportDownloadFromButton}
                  onEdit={() => setIsEditUiOpen(true)}
                  onRecordingChange={handleOnRecordingChange}
                  onRevertTrim={() => revertTrimRecording(recording)}
                  onTrim={() => setIsTrimmingUiOpen(true)}
                  recording={recording}
                  transcriptsEnabled={transcriptsEnabled}
                  onViewTranscript={onViewTranscript}
                  useMts={useMts}
                />
                <PublishControls
                  recording={recording}
                  onChange={handleOnRecordingChange}
                />
              </Flex>

              <ConfirmationDialog
                size="tiny"
                isShowing={isDeleteUiOpen}
                title={t('delete-recording-confirmation-modal.title')}
                message={t('delete-recording-confirmation-modal.message')}
                primaryButtonText={t(
                  'delete-recording-confirmation-modal.confirm',
                )}
                colorPattern="danger"
                secondaryButtonText={t(
                  'delete-recording-confirmation-modal.cancel',
                )}
                onConfirm={deleteRecordingHandler}
                onCancel={() => setIsDeleteUiOpen(false)}
              />

              {relatedRecordings?.length > 0 && (
                <Flex flexDirection="column" data-testid="related-recordings">
                  <Text pattern="headingFour" element="h3" my={1}>
                    {recording.eventPart.name
                      ? t('recording-library.other-videos-from-with-name', {
                          type: t(
                            `recording-library.event-parts.${recording.eventPart.type}`,
                          ),
                          name: recording.eventPart.name,
                        })
                      : t('recording-library.other-videos-from', {
                          type: t(
                            `recording-library.event-parts.${recording.eventPart.type}`,
                          ),
                        })}
                  </Text>
                  <RelatedRecordingContainer>
                    <TileView
                      recordings={relatedRecordings}
                      onRecordingClick={onRecordingClick}
                      isShowingDetails={false}
                      onRecordingDownload={handleReportDownloadFromRelated}
                    />
                  </RelatedRecordingContainer>
                </Flex>
              )}
            </Flex>
            <EditRecordingModal
              originalRecording={recording}
              isEditSaving={isTrimSaving}
              isOpen={isTrimmingUiOpen}
              isRevertingRecording={isRevertSaving}
              onClose={() => setIsTrimmingUiOpen(false)}
              onEditSave={trimRecording}
              onRevert={() => revertTrimRecording(recording)}
              reloadRecordingSource={reloadSource}
            />
            <SidebarContainer
              recording={recording}
              isShowing={isEditUiOpen}
              onSuccess={onSidebarUpdateSuccess}
              onError={onSidebarUpdateError}
              onClose={() => setIsEditUiOpen(false)}
            />
            {customThumbnailsEnabled && (
              <ThumbnailSidePanelModal
                isShowing={isThumbnailUiOpen}
                onClose={() => setIsThumbnailUiOpen(false)}
                recording={recording}
              />
            )}
          </>
        ) : (
          <Skeleton />
        )}
      </Loading>
    </Flex>
  );
};

DetailedPageComponent.propTypes = {
  id: PropTypes.string.isRequired,
  customThumbnailsEnabled: PropTypes.bool.isRequired,
  transcriptsEnabled: PropTypes.bool.isRequired,
  useMts: PropTypes.bool.isRequired,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
  withReduxProvider,
  withAuthProvider,
  withEventProvider,
  withUserProvider,
  withRegistrationProvider,
  withSlicesProvider,
)(DetailedPageComponent);
