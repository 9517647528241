import {
  EXPERIENCE_TEMPLATE,
  EXPERIENCE_TEMPLATE_TO_EVENT_TEMPLATE,
} from '@features/event-creation-wizard/constants/event-experience';
import { flattenFeaturesForPayload } from '@features/event-creation-wizard/redux/add-ons/add-ons-slice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import snakecaseKeys from '@util/snakecase-keys';
import { isEmpty } from 'lodash';

import { templates } from '../../constants/templates';

const removeEmptyFields = obj => {
  return Object.fromEntries(
    Object.entries(obj).filter(entry => entry[1] !== '' && entry[1] != null),
  );
};

const removeEmptyObjects = arr => {
  return arr
    .map(obj => removeEmptyFields(obj))
    .filter(obj => Object.keys(obj).length !== 0);
};

const getAutoPublish = (eventAccess, autoPublish) => {
  return eventAccess === 'free' && autoPublish;
};

/**
 * allowed templates can be found here (look for "EVENT_TEMPLATES" variable):
 * app/helpers/event_creation_wizard_helper.rb
 */
const getEventTemplate = (template, virtualLite) => {
  if (Object.values(EXPERIENCE_TEMPLATE).includes(template.id)) {
    return EXPERIENCE_TEMPLATE_TO_EVENT_TEMPLATE[template.id];
  }

  return template.id === 'virtual_lite'
    ? virtualLite.webinarStyle
    : template.id;
};

const buildPayload = ({
  org,
  template,
  basicDetails,
  venue,
  speakers,
  expo,
  welcomeMessage,
  eventAccess,
  eventType,
  sessions,
  eventLocation,
  purpose,
  addOns,
  flow,
  virtualLite,
  series,
}) => {
  let res = removeEmptyFields({
    org_external_id: org.id,
    event_template: getEventTemplate(template, virtualLite),
    event_base_setup: removeEmptyFields({
      name: basicDetails.name,
      time_start: basicDetails.startDate,
      time_end: basicDetails.endDate,
      timezone: basicDetails.timezone,
      picture_url: basicDetails.imageUrl,
      event_type: eventType,
      event_password: basicDetails.eventPassword,
    }),
    welcome_message: welcomeMessage.value,
    event_access: eventAccess,
    event_parts: flattenFeaturesForPayload(venue),
    event_add_ons: flattenFeaturesForPayload(addOns),
    auto_publish: templates[template.id]
      ? getAutoPublish(eventAccess, templates[template.id].autoPublish)
      : false,
    speakers: removeEmptyObjects([speakers]),
    expos: removeEmptyObjects([
      {
        name: expo.name,
        email: expo.email,
        about: expo.description,
      },
    ]),
    venue_type: eventLocation.type,
    ...(['hybrid', 'onsite'].includes(eventLocation.type) &&
    !isEmpty(eventLocation.address)
      ? {
          address: snakecaseKeys(eventLocation.address),
          location_name: eventLocation.name,
        }
      : {}),
    event_intent: purpose,
    onboarding_flow: flow === 'onboarding',
    ...(series?.isSeriesSelected ? { event_series: [series.seriesId] } : {}),
  });

  const session = removeEmptyFields({
    title: sessions.title,
    description: sessions.description,
    picture_url: sessions.image,
  });
  if (Object.keys(session).length !== 0) {
    res.session = session;
  }

  return res;
};

const createEvent = createAsyncThunk(
  'createEvent',
  async (_, { getState, extra: { hopinApi } }) => {
    const {
      app,
      auth,
      org,
      template,
      basicDetails,
      venue,
      speakers,
      expo,
      welcomeMessage,
      eventAccess,
      eventType,
      sessions,
      eventLocation,
      purpose,
      addOns,
      virtualLite,
      series,
    } = getState();
    const token = auth.token;

    const body = buildPayload({
      org,
      template,
      basicDetails,
      venue,
      speakers,
      expo,
      welcomeMessage,
      eventAccess,
      eventType,
      sessions,
      eventLocation,
      purpose,
      addOns,
      flow: app.flow,
      virtualLite,
      series,
    });

    try {
      const response = await hopinApi.createEventFromWizard(token, body);
      return response;
    } catch (error) {
      if (error && error.response) {
        const { code, title } = await error.response.json();
        return { error: { code, message: title } };
      }
      throw error;
    }
  },
);

const initialState = {
  createEventError: null,
};

const createEventSlice = createSlice({
  name: 'event-creation-wizard-create-event',
  initialState,
  reducers: {
    clearCreateEventError(state) {
      state.createEventError = initialState.createEventError;
    },
    setCreateEventError(state, action) {
      state.createEventError = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createEvent.pending, state => {
        state.createEventError = null;
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.createEventError = action.error;
      });
  },
});

export { createEvent };

export default createEventSlice.reducer;

export const {
  clearCreateEventError,
  setCreateEventError,
} = createEventSlice.actions;
