import { useLocalization } from '@features/localization';
import { selectEvent } from '@features/recordings-backup/redux/slices/event/event-selectors';
import { getRecordingDownloadUrl } from '@features/recordings-backup/redux/slices/recordings/recordings-selectors';
import {
  getSecondaryLinks,
  localiseDate,
} from '@features/recordings-backup/utils/utils';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { InplaceEditor } from '@hopin-team/ui-inplace-editor';
import { buildRecordingSubtitle } from '@hopin-team/ui-recording-card';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { EVENT_PART, MAX_TITLE_LENGTH, RANK, WHEN } from '../../../constants';
import { RecordingButtons } from '../recording-buttons';
import {
  ControlsContainerWrapped,
  LastTrimmedText,
  StyledDescription,
} from './recording-controls.styled';

export const RecordingControls = ({
  customThumbnailsEnabled,
  onChangeThumbnail,
  onDelete,
  onDownload,
  onEdit,
  onRecordingChange,
  onRevertTrim,
  onTrim,
  recording,
  transcriptsEnabled,
  onViewTranscript,
  useMts,
}) => {
  const { t, language } = useLocalization('recording-library');
  const { slug } = useSelector(selectEvent);
  const downloadUrl = useSelector(state =>
    getRecordingDownloadUrl(state, recording.id),
  );

  const { backupHref, rehearsalHref } = getSecondaryLinks(
    slug,
    recording?.eventPart,
  );
  const showBackupLink = !!(
    backupHref &&
    recording?.rank === RANK.MAIN &&
    recording?.whenInTime === WHEN.IN_EVENT
  );

  const recordingButtonProps = {
    recording,
    onDownload,
    onDelete,
    onEdit,
    onRevertTrim,
    onTrim,
    customThumbnailsEnabled,
    onChangeThumbnail,
    downloadUrl,
    transcriptsEnabled,
    onViewTranscript,
    useMts,
  };

  const subtitle = buildRecordingSubtitle({
    startedAt: recording.startedAt,
    endedAt: recording.endedAt,
    locale: language,
  });

  return (
    <ControlsContainerWrapped data-testid="recording-controls">
      <Flex flexDirection="column">
        <Flex flexDirection="row" justifyContent="space-between">
          <RecordingButtons {...recordingButtonProps} />
          {recording.lastTrimmedTime && (
            <Flex>
              <LastTrimmedText
                data-testid="last-trimmed"
                pattern="caption"
                color="grey-700"
              >
                {t('last-trimmed', {
                  formattedDatetime: localiseDate(
                    recording.lastTrimmedTime,
                    language,
                    {
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    },
                  ),
                })}
              </LastTrimmedText>
            </Flex>
          )}
        </Flex>

        <Flex mt={2} mb={1}>
          <InplaceEditor
            value={{ current: recording.title || '' }}
            handleInputChange={newTitle =>
              onRecordingChange({ title: newTitle })
            }
            maxCount={MAX_TITLE_LENGTH}
            maxLengthMessage={t('reached-character-limit')}
          />
        </Flex>
        <Text mb="2" pattern="Emphasis" color="grey-600">
          {subtitle}
        </Text>
        <Flex mt={1}>
          {recording?.description ? (
            <StyledDescription
              data-testid="description-text"
              pattern="body"
              dangerouslySetInnerHTML={{ __html: recording.description }}
            />
          ) : (
            <Button
              isInline
              size="medium"
              isGhost
              leadingIcon="plus"
              onClick={onEdit}
              data-testid="description-link-btn"
            >
              {t('add-description')}
            </Button>
          )}
        </Flex>
        {showBackupLink && (
          <Box
            data-testid="backup-link"
            py={2}
            mt={3}
            removeBorders={['borderBottom', 'borderRight', 'borderLeft']}
            border="grey-300"
          >
            <Flex flexDirection="row">
              <Icon
                aria-hidden="true"
                name="info-outlined"
                color="theme-primary"
                mr={1}
                mt={0.4}
              />
              <Flex>
                <Text
                  linkStyle="underline"
                  pattern="body"
                  color="grey-600"
                  dangerouslySetInnerHTML={{
                    __html: t('link-with-backup', {
                      eventPartName: recording?.eventPart?.name,
                      backupHref: backupHref,
                      rehearsalHref: rehearsalHref,
                    }),
                  }}
                />
              </Flex>
            </Flex>
          </Box>
        )}
      </Flex>
    </ControlsContainerWrapped>
  );
};

RecordingControls.propTypes = {
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onEdit: PropTypes.func,
  onRecordingChange: PropTypes.func,
  onRevertTrim: PropTypes.func,
  onTrim: PropTypes.func,
  customThumbnailsEnabled: PropTypes.bool,
  transcriptsEnabled: PropTypes.bool,
  onChangeThumbnail: PropTypes.func,
  onViewTranscript: PropTypes.func,
  useMts: PropTypes.bool,
  recording: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rank: PropTypes.oneOf([RANK.MAIN, RANK.BACKUP]),
    whenInTime: PropTypes.oneOf([WHEN.PRE_EVENT, WHEN.IN_EVENT]),
    startedAt: PropTypes.string,
    createdAt: PropTypes.string,
    endedAt: PropTypes.string,
    title: PropTypes.string,
    playableUrl: PropTypes.string,
    description: PropTypes.string,
    eventPart: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf([EVENT_PART.STAGE, EVENT_PART.SESSION]),
    }),
    isProcessing: PropTypes.bool,
    lastTrimmedTime: PropTypes.string,
  }),
};
