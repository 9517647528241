import DatePickerComponents from '@features/event-creation-wizard/components/date-pickers/date-picker-components';
import { useEventCreationWizard } from '@features/event-creation-wizard/event-creation-wizard-provider';
import { redirectOnClose } from '@features/event-creation-wizard/utils/redirection';
import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import React, { useCallback } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ExperiencePreview } from './experience-preview/experience-preview-component';
import ExperienceSelector from './experience-selector/experience-selector-component';
import { useExperienceStepForm } from './hooks/use-experience-step-form';
import SeriesSelector from './series-selector/series-selector-component';
import {
  ContinueAction,
  CustomCloseButton,
  FormWrapper,
  FullScreenWrapper,
  GridWrapper,
  StyledTextField,
} from './styles';

const ExperienceDetails = () => {
  const { t } = useLocalization('event-creation-wizard');
  const methods = useExperienceStepForm();
  const { dashboardUrl } = useEventCreationWizard();

  const {
    org: { id: orgId },
    flags: { dashboardOptedIn, appEnvironment },
  } = useSelector(state => state);

  const redirectToDashboard = useCallback(() => {
    redirectOnClose({ dashboardOptedIn, dashboardUrl, appEnvironment, orgId });
  }, [appEnvironment, dashboardOptedIn, dashboardUrl, orgId]);

  return (
    <FullScreenWrapper>
      <CustomCloseButton
        onClick={redirectToDashboard}
        aria-label={t('common.close')}
      />
      <FormProvider {...methods}>
        <GridWrapper>
          <Details />
          <ExperiencePreview />
        </GridWrapper>
      </FormProvider>
    </FullScreenWrapper>
  );
};

const Details = () => {
  const { t, dateLocale } = useLocalization('event-creation-wizard');

  const {
    template: { id: templateId },
  } = useSelector(state => state);

  const { isEventSeriesEnabled } = useSelector(state => state.flags);

  const {
    watch,
    errors,
    trigger,
    setValue,
    register,
    onClickNext,
  } = useFormContext();

  return (
    <FormWrapper onSubmit={onClickNext} data-testid="create-event-form">
      <Text pattern="title" color="grey-black" mb={1.5}>
        {t('steps.event-experience.title')}
      </Text>
      <StyledTextField
        mb={2.5}
        name="name"
        ref={register}
        hasErrors={Boolean(errors.name)}
        autocomplete="off"
        errorMessage={errors?.name?.message}
        label={t(`steps.event-experience.form.name.label`)}
        placeholder={t(`steps.event-experience.form.name.placeholder`)}
        isRequired
      />

      <DatePickerComponents
        t={t}
        watch={watch}
        errors={errors}
        setValue={setValue}
        register={register}
        trigger={trigger}
        dateLocale={dateLocale}
        baseTranslationNamespace="steps.event-experience"
      />

      {isEventSeriesEnabled && <SeriesSelector />}

      <ExperienceSelector />

      <ContinueAction type="submit" mt={2.5}>
        {t(`steps.event-experience.actions.create_${templateId}`)}
      </ContinueAction>
    </FormWrapper>
  );
};

export default ExperienceDetails;
