const { useSelector } = require('react-redux');

const useIsFreePlan = () => {
  const { event, organization } = useSelector(({ event, organization }) => ({
    event,
    organization,
  }));
  if (!event) return false;

  const { phase } = event;
  const { currentPaymentPlan } = organization;

  const isFreePlan = currentPaymentPlan.name === 'Free';
  const isFreeTrialPlan = currentPaymentPlan.name === 'Free_trial';
  const isPublishAllowed = currentPaymentPlan.features.publishEvent;

  return {
    isFreePlan: Boolean(isFreePlan && !isPublishAllowed),
    isFreeTrialPlan: Boolean(isFreeTrialPlan),
    isDraft: phase === 'Draft',
    isLive: phase === 'Live',
  };
};

export default useIsFreePlan;
