import addOnsReducer from '@features/event-creation-wizard/redux/add-ons/add-ons-slice';
import basicDetailsReducer from '@features/event-creation-wizard/redux/step-specific-state/basic-details-slice';
import eventAccessReducer from '@features/event-creation-wizard/redux/step-specific-state/event-access-slice';
import expoReducer from '@features/event-creation-wizard/redux/step-specific-state/expo-slice';
import purposeReducer from '@features/event-creation-wizard/redux/step-specific-state/purpose-slice';
import seriesReducer from '@features/event-creation-wizard/redux/step-specific-state/series-slice';
import sessionsReducer from '@features/event-creation-wizard/redux/step-specific-state/sessions-slice';
import speakersReducer from '@features/event-creation-wizard/redux/step-specific-state/speaker-slice';
import templateReducer from '@features/event-creation-wizard/redux/step-specific-state/template-slice';
import venueReducer from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import virtualLiteReducer from '@features/event-creation-wizard/redux/step-specific-state/virtual-lite-slice';
import welcomeMessageReducer from '@features/event-creation-wizard/redux/step-specific-state/welcome-message-slice';
import { combineReducers } from 'redux';

import appReducer from './app/app-slice';
import createEventReducer from './app/create-event';
import authReducer from './auth/auth-slice';
import eventLocationReducer from './event-location/event-location-slice';
import eventTypeReducer from './event-type/event-type-slice';
import flagsReducer from './flags/flags-slice';
import orgReducer from './org/org-slice';
import userReducer from './user/user-slice';

const eventCreationWizardReducer = combineReducers({
  app: appReducer,
  createEvent: createEventReducer,
  auth: authReducer,
  user: userReducer,
  flags: flagsReducer,
  org: orgReducer,
  speakers: speakersReducer,
  basicDetails: basicDetailsReducer,
  template: templateReducer,
  series: seriesReducer,
  venue: venueReducer,
  expo: expoReducer,
  eventAccess: eventAccessReducer,
  welcomeMessage: welcomeMessageReducer,
  sessions: sessionsReducer,
  eventType: eventTypeReducer,
  eventLocation: eventLocationReducer,
  purpose: purposeReducer,
  addOns: addOnsReducer,
  virtualLite: virtualLiteReducer,
});

export default eventCreationWizardReducer;
