import '@hopin-team/ui-theme';

import hopinApi from '@api/hopin';
import AlertsProvider from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import { EXPERIENCE_TEMPLATE } from '@features/event-creation-wizard/constants/event-experience';
import { LocalizationProvider } from '@features/localization';
import { ReactIDProvider } from '@hopin-team/react-id';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import * as P from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useAsync } from 'react-use';

import EventCreationWizardProvider from './event-creation-wizard-provider';
import GlobalStyles from './global-styles';
import setupEventCreationWizardStore from './redux/event-creation-wizard-store';
import { getInitialBasicDetailsState } from './redux/step-specific-state/basic-details-slice';
import Routes from './routes';

const EventCreationWizardRoot = ({
  orgId,
  appEnvironment,
  userId,
  dashboardOptedIn,
  dashboardUrl,
  numRegistrationsIncluded,
  defaultMaxEventLengthHours,
  defaultMaxWebinarLengthHours,
  isOnFreePlan,
  isOnStarterPlan,
  isOnAdvancedPlan,
  googleMapsApiKey,
  subscriptionPlanTier,
  basicDetailsDynamicGraphicEnabled,
  eventsLiteEnabled,
  eventsProEnabled,
  defaultEventImageUrl,
  orgLogo,
  orgName,
  maxAttendees,
  essentialsWizardEventSimplification,
  isEventSeriesEnabled,
  isEventsProPlansEnabled,
  onsiteHybridEventsEnabled,
}) => {
  const userData = useAsync(async () => {
    const userToken = await hopinApi.getUserToken();
    const userDetails = await hopinApi.getUser(userToken.token);

    return { userToken, userDetails };
  });

  const { store } = React.useMemo(() => {
    if (!userData.value) {
      return { store: null };
    }

    const { userToken, userDetails } = userData.value;

    return setupEventCreationWizardStore(hopinApi, {
      auth: userToken,
      user: userDetails,
      flags: {
        dashboardOptedIn,
        appEnvironment,
        basicDetailsDynamicGraphicEnabled,
        essentialsWizardEventSimplification,
        isEventsProPlansEnabled,
        isEventSeriesEnabled,
      },
      org: {
        id: orgId,
        name: orgName,
        logo: orgLogo,
        isOnFreePlan,
        isOnStarterPlan,
        isOnAdvancedPlan,
        numRegistrationsIncluded,
        subscriptionPlanTier,
        features: {
          eventsLiteEnabled,
          eventsProEnabled,
          onsiteHybridEventsEnabled,
        },
        maxAttendees,
      },
      template: {
        id: essentialsWizardEventSimplification
          ? EXPERIENCE_TEMPLATE.WEBINAR
          : '',
      },
      basicDetails: getInitialBasicDetailsState(
        defaultMaxEventLengthHours,
        defaultEventImageUrl,
        defaultMaxWebinarLengthHours,
      ),
    });
  }, [
    userData.value,
    dashboardOptedIn,
    appEnvironment,
    basicDetailsDynamicGraphicEnabled,
    essentialsWizardEventSimplification,
    isEventSeriesEnabled,
    orgId,
    orgName,
    orgLogo,
    isOnFreePlan,
    isOnStarterPlan,
    isOnAdvancedPlan,
    numRegistrationsIncluded,
    subscriptionPlanTier,
    eventsLiteEnabled,
    eventsProEnabled,
    onsiteHybridEventsEnabled,
    maxAttendees,
    defaultMaxEventLengthHours,
    defaultEventImageUrl,
    defaultMaxWebinarLengthHours,
  ]);

  const userExternalId = userData.value?.userDetails.external_id;

  if (userData.loading) {
    return (
      <Helmet>
        <title>Create Event | RingCentral Events</title>
      </Helmet>
    );
  }

  if (userData.error) {
    window.location.pathname = '/users/sign_in';
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Create Event | RingCentral Events</title>
      </Helmet>
      <BrowserRouter>
        <Provider store={store}>
          <LocalizationProvider>
            <AlertsProvider>
              <ReactIDProvider>
                <EventCreationWizardProvider
                  userId={userId}
                  orgId={orgId}
                  googleMapsApiKey={googleMapsApiKey}
                  userExternalId={userExternalId}
                  dashboardUrl={dashboardUrl}
                >
                  <Routes />
                </EventCreationWizardProvider>
              </ReactIDProvider>
            </AlertsProvider>
          </LocalizationProvider>
        </Provider>
      </BrowserRouter>
      <GlobalStyles />
    </>
  );
};

EventCreationWizardRoot.propTypes = {
  orgId: P.string,
  appEnvironment: P.string.isRequired,
  userId: P.number.isRequired,
  defaultMaxEventLengthHours: P.number.isRequired,
  defaultMaxWebinarLengthHours: P.number.isRequired,
  numRegistrationsIncluded: P.number.isRequired,
  isOnFreePlan: P.bool.isRequired,
  isOnStarterPlan: P.bool.isRequired,
  isOnAdvancedPlan: P.bool.isRequired,
  dashboardOptedIn: P.bool.isRequired,
  dashboardUrl: P.string,
  googleMapsApiKey: P.string,
  subscriptionPlanTier: P.string,
  basicDetailsDynamicGraphicEnabled: P.bool.isRequired,
  eventsLiteEnabled: P.bool.isRequired,
  eventsProEnabled: P.bool.isRequired,
  defaultEventImageUrl: P.string.isRequired,
  orgLogo: P.string.isRequired,
  orgName: P.string,
  maxAttendees: P.number,
  essentialsWizardEventSimplification: P.bool,
  isEventSeriesEnabled: P.bool,
  isEventsProPlansEnabled: P.bool,
  onsiteHybridEventsEnabled: P.bool,
};

export default compose(
  withErrorHandling({ tag: LOGGER_NAMES.EVENT_CREATION_WIZARD }),
  withThemeProvider,
)(EventCreationWizardRoot);
