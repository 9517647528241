import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSeriesSelected: false,
  seriesId: null,
};

const series = createSlice({
  name: 'event-creation-wizard-series',
  initialState,
  reducers: {
    setIsSeriesSelected: (state, action) => {
      state.isSeriesSelected = action.payload;
      return state;
    },
    setSeriesId: (state, action) => {
      state.seriesId = action.payload;
      return state;
    },
  },
});

const {
  reducer,
  actions: { setIsSeriesSelected, setSeriesId },
} = series;

export { setIsSeriesSelected, setSeriesId };

export default reducer;
