import { EXPERIENCE_TEMPLATE } from '@features/event-creation-wizard/constants/event-experience';
import { useEventCreationWizard } from '@features/event-creation-wizard/event-creation-wizard-provider';
import { setTemplate } from '@features/event-creation-wizard/redux/step-specific-state/template-slice';
import AddressPicker from '@features/events/address-picker';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  CustomRadio,
  CustomToggle,
  OnsiteDetailsWrapper,
  OnsiteLabel,
  Pill,
  RadioButtonCard,
  RadioButtonInput,
  RadioDescription,
  RadioGroupContainer,
  RadioLabel,
} from './styles';

const ExperienceSelector = () => {
  const { t } = useLocalization(
    'event-creation-wizard.steps.event-experience.form.event-type',
  );
  const { errors, watch, setValue, register } = useFormContext();
  const { googleMapsApiKey } = useEventCreationWizard();
  const dispatch = useDispatch();

  const {
    eventLocation: { address: currentLocationAddress },
    template: { id: templateId },
    flags: { isEventsProPlansEnabled },
    org: {
      features: { onsiteHybridEventsEnabled },
    },
  } = useSelector(state => state);

  const options = useMemo(
    () =>
      [
        {
          id: [EXPERIENCE_TEMPLATE.WEBINAR],
          label: 'webinar.label',
          value: EXPERIENCE_TEMPLATE.WEBINAR,
          description: 'webinar.description',
          hidden: false,
        },
        {
          id: [EXPERIENCE_TEMPLATE.VIRTUAL],
          label: 'virtual.label',
          value: EXPERIENCE_TEMPLATE.VIRTUAL,
          description: 'virtual.description',
          hidden: false,
        },
        {
          id: [EXPERIENCE_TEMPLATE.HYBRID, EXPERIENCE_TEMPLATE.ONSITE],
          label: 'hybrid.label',
          value: EXPERIENCE_TEMPLATE.HYBRID,
          description: 'hybrid.description',
          hidden: !onsiteHybridEventsEnabled && isEventsProPlansEnabled,
        },
      ].filter(option => !option.hidden),
    [isEventsProPlansEnabled, onsiteHybridEventsEnabled],
  );

  const eventLocationAddress = watch(
    'eventLocationAddress',
    typeof currentLocationAddress === 'string'
      ? JSON.parse(currentLocationAddress)
      : currentLocationAddress,
  );

  const onAddressChange = address =>
    setValue('eventLocationAddress', JSON.stringify(address)); // This version of react-form-hook does not support object values

  useEffect(
    function resetLocationAndAddress() {
      if (
        templateId === EXPERIENCE_TEMPLATE.HYBRID ||
        templateId === EXPERIENCE_TEMPLATE.ONSITE
      ) {
        return;
      }

      setValue('eventLocationName', undefined);
      setValue('eventLocationAddress', '{}');
    },
    [setValue, templateId],
  );

  const handleEventTypeChange = value => {
    dispatch(setTemplate(value));
  };

  return (
    <>
      <Text pattern="captionMedium" color="grey-black" mb={1}>
        {t('title')}
      </Text>
      <Text pattern="caption" color="grey-600" mb={1}>
        {t('subtitle')}
      </Text>

      <RadioGroupContainer role="radiogroup">
        {options.map(option => (
          <RadioButtonCard
            key={option.id[0]}
            isSelected={option.id.includes(templateId)}
          >
            <Flex mr={1.5}>
              <RadioButtonInput
                type="radio"
                name="eventType"
                value={option.value}
                checked={option.id.includes(templateId)}
                onChange={event => {
                  const value = event.target.value;
                  if (value !== templateId) {
                    handleEventTypeChange(value);
                  }
                }}
              />
              <CustomRadio isSelected={option.id.includes(templateId)} />
            </Flex>

            <Flex flexGrow={1} flexDirection="column">
              <div>
                <RadioLabel
                  pattern="emphasis"
                  element="h6"
                  color="grey-black"
                  mb={0.5}
                >
                  {t(option.label)}{' '}
                  <Pill isActive={option.id.includes(templateId)}>
                    {t('event')}
                  </Pill>
                </RadioLabel>

                <RadioDescription
                  pattern="captionMedium"
                  element="p"
                  color="grey-600"
                >
                  {t(option.description)}
                </RadioDescription>
              </div>

              {option.id.includes(EXPERIENCE_TEMPLATE.HYBRID) &&
                [
                  EXPERIENCE_TEMPLATE.HYBRID,
                  EXPERIENCE_TEMPLATE.ONSITE,
                ].includes(templateId) && (
                  <Box mt={1}>
                    <Flex justifyContent="space-between">
                      <OnsiteLabel pattern="emphasis" color="grey-black">
                        {t('hybrid.onsite-pill-label')}
                      </OnsiteLabel>
                      <CustomToggle
                        role="checkbox"
                        isToggled={templateId === EXPERIENCE_TEMPLATE.ONSITE}
                        isDisabled={[
                          EXPERIENCE_TEMPLATE.WEBINAR,
                          EXPERIENCE_TEMPLATE.VIRTUAL,
                        ].includes(templateId)}
                        sizeOption="large"
                        name="eventOnsite"
                        onChange={event =>
                          handleEventTypeChange(
                            event.target.checked
                              ? EXPERIENCE_TEMPLATE.ONSITE
                              : EXPERIENCE_TEMPLATE.HYBRID,
                          )
                        }
                      />
                    </Flex>
                  </Box>
                )}
            </Flex>
          </RadioButtonCard>
        ))}
      </RadioGroupContainer>
      {[EXPERIENCE_TEMPLATE.HYBRID, EXPERIENCE_TEMPLATE.ONSITE].includes(
        templateId,
      ) && (
        <OnsiteDetailsWrapper mt={2.5}>
          <TextField
            name="eventLocationName"
            ref={register}
            hasErrors={Boolean(errors.eventLocationName)}
            errorMessage={errors?.eventLocationName?.message}
            onChange={e => setValue('eventLocationName', e.target.value)}
            label={t(`hybrid.venue-name.label`)}
            placeholder={t(`hybrid.venue-name.placeholder`)}
          />

          <AddressPicker
            address={
              eventLocationAddress && typeof eventLocationAddress === 'string'
                ? JSON.parse(eventLocationAddress)
                : eventLocationAddress
            }
            onAddressChange={onAddressChange}
            googleMapsApiKey={googleMapsApiKey}
            label={t('address.label')}
            placeholder={t('address.placeholder')}
            required={false}
          />
        </OnsiteDetailsWrapper>
      )}
      <input hidden name="eventLocationAddress" ref={register} />
    </>
  );
};

export default ExperienceSelector;
