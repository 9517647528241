import Alert from '@components/icons/alert';
import { useLocalization } from '@features/localization';
import { rem } from 'polished';
import { arrayOf, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  padding: ${rem(16)};
  background-color: var(--color-red-100);
  border-radius: ${rem(6)};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  color: var(--color-red-500);
`;

const ContentContainer = styled.div`
  margin-left: ${rem(12)};
  flex: 1;
`;

const Heading = styled.h3`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: 1.2;
  color: var(--color-red-700);
  margin: 0 0 ${rem(16)} 0;
`;

const ErrorList = styled.ul`
  font-family: var(--font-family);
  font-size: ${rem(14)};
  line-height: 1.5;
  color: var(--color-red-600);
  text-align: left;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: ${rem(4)};
  }
`;

const ErrorModal = ({ errors }) => {
  const { t } = useLocalization('magic-links.tabs.upload-csv');

  return (
    <Section>
      <FlexContainer>
        <IconContainer>
          <Alert color="var(--color-red-500)" />
        </IconContainer>
        <ContentContainer>
          <Heading>{t('errors-found')}</Heading>
          {Array.isArray(errors) && errors.length > 0 ? (
            <ErrorList data-testid="error-list">
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ErrorList>
          ) : (
            <ErrorList>
              <li>{t('uploaded-invalid-file')}</li>
            </ErrorList>
          )}
        </ContentContainer>
      </FlexContainer>
    </Section>
  );
};

ErrorModal.propTypes = {
  errors: arrayOf(string),
};

ErrorModal.defaultProps = {
  errors: [],
};

export default ErrorModal;
