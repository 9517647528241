import hopinApi from '@api/hopin';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import { Toggle } from '@hopin-team/ui-toggle';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';

const SeriesSelector = () => {
  const { t } = useLocalization(
    'event-creation-wizard.steps.event-experience.form.series',
  );
  const {
    org: { id: orgId },
  } = useSelector(state => state);
  const { search: searchString } = useLocation();
  const { errors, watch, control, reset } = useFormContext();

  const isSeriesSelected = watch('isSeriesSelected');
  const seriesId = watch('seriesId');
  const eventSeries = useAsync(async () => {
    const eventSeries = await hopinApi.getEventSeriesByOrg(orgId);
    return get(eventSeries, 'data.organization.eventSeries.edges', []);
  });

  const eventSeriesOptions = useMemo(() => {
    return (eventSeries?.value ?? []).map(({ node }) => ({
      label: {
        subtext: `${node.eventsCount} events`,
        text: node.name,
      },
      leadingIcon: 'folder',
      value: node.id,
    }));
  }, [eventSeries]);

  useEffect(() => {
    const searchParams = new URLSearchParams(searchString);
    const seriesId = searchParams.get('seriesId');
    if (
      seriesId &&
      !isEmpty(eventSeriesOptions) &&
      eventSeriesOptions.some(series => series.value === seriesId)
    ) {
      reset({
        isSeriesSelected: true,
        seriesId,
      });
    }
  }, [reset, searchString, eventSeriesOptions]);

  return (
    !isEmpty(eventSeriesOptions) && (
      <Flex flexDirection="column" mb="1.25">
        <Flex flexDirection="row" mb="1.25" justifyContent="space-between">
          <Flex flexDirection="column">
            <Text pattern="captionMedium" color="grey-black" mb={1}>
              {t('title')}
            </Text>
            <Text pattern="caption" color="grey-600" mb={1}>
              {t('subtitle')}
            </Text>
          </Flex>
          <Controller
            name="isSeriesSelected"
            control={control}
            render={({ value, onChange }) => (
              <Toggle
                isToggled={value}
                sizeOption="large"
                onChange={() => onChange(!value)}
                isDisabled={isEmpty(eventSeriesOptions)}
              />
            )}
          />
        </Flex>
        {isSeriesSelected && (
          <Flex flexDirection="column">
            <Controller
              name="seriesId"
              control={control}
              render={({ value, onChange }) => (
                <Select
                  label={t('series-select')}
                  placeholder={t('series-select-placeholder')}
                  options={eventSeriesOptions}
                  value={value}
                  hasErrors={errors['seriesId']}
                  errorMessage={t('required')}
                  setValue={onChange}
                  leadingIcon={seriesId ? 'folder' : ''}
                  visibleOptionsCount={6}
                />
              )}
            />
          </Flex>
        )}
      </Flex>
    )
  );
};

export default SeriesSelector;
