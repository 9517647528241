import { useModal } from '@components/modal/modal-context';
import { Box } from '@hopin-team/ui-box';
import { bool, object } from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

import CSVSelect from './components/csv-select';
import DownloadCSV from './components/download-csv';
import ErrorModal from './components/error-modal';
import SuccessModal from './components/success-modal';
import TicketType from './components/ticket-type';
import UploadCSV from './components/upload-csv';
import useBulkInviteForm from './use-bulk-invite-form';

const BulkInviteComponent = ({ event, linesLimitCsvUploadEnabled }) => {
  const { setContent, setIsModalOpen } = useModal();

  const handleSuccess = () => {
    setContent(<SuccessModal />);
    setIsModalOpen(true);
  };
  const handleError = (errors = []) => {
    setContent(<ErrorModal errors={errors} />);
    setIsModalOpen(true);
  };
  const { control, errors, register, onSubmit } = useBulkInviteForm({
    event,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return (
    <Box data-testid="bulk-invite-component" mx={1.5} py={3}>
      <form id="bulk-invite" data-testid="bulk-invite-form" onSubmit={onSubmit}>
        <input
          data-testid="event-id-input"
          type="hidden"
          name="event_id"
          id="event_id"
          value={event.id}
          ref={register}
        />
        <DownloadCSV event={event} />
        <Controller
          control={control}
          defaultValue={[]}
          name="csv_file"
          render={({ onChange }) => (
            <CSVSelect
              error={errors['csv_file']}
              onChange={files => onChange(files)}
              linesLimitCsvUploadEnabled={linesLimitCsvUploadEnabled}
            />
          )}
        />
        <TicketType
          event={event}
          inputRef={register}
          error={errors['persona_id']}
        />
        <UploadCSV event={event} />
      </form>
    </Box>
  );
};

BulkInviteComponent.propTypes = {
  event: object.isRequired,
  linesLimitCsvUploadEnabled: bool.isRequired,
};

export default BulkInviteComponent;
