import { useLocalization } from '@features/localization';
import {
  Menu,
  MenuContent,
  MenuSelect,
  MenuSelectItem,
} from '@hopin-team/ui-menu';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  RecordingButtonsContainer,
  StyledButticon,
  StyledMenuSelectItem,
} from './recording-buttons.styled';

export const RecordingButtons = ({
  onDownload,
  onDelete,
  onEdit,
  onRevertTrim,
  onTrim,
  recording,
  customThumbnailsEnabled,
  onChangeThumbnail,
  downloadUrl,
  transcriptsEnabled,
  onViewTranscript,
  useMts,
}) => {
  const { t } = useLocalization('recording-library.buttons');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const enableRevert = !!recording.lastTrimmedTime && !recording.isProcessing;

  return (
    <RecordingButtonsContainer data-testid="recording-buttons">
      <ToolTip align="top" tip={t('edit-details')}>
        <StyledButticon
          title={t('edit-details')}
          icon="edit"
          onClick={onEdit}
          size="tiny"
          data-testid="edit-icon-btn"
          isOutlined
        />
      </ToolTip>
      {customThumbnailsEnabled && (
        <ToolTip align="top" tip={t('change-thumbnail')}>
          <StyledButticon
            title={t('change-thumbnail')}
            icon="image-two-tone"
            onClick={onChangeThumbnail}
            size="tiny"
            data-testid="change-thumbnail-icon-btn"
            isOutlined
          />
        </ToolTip>
      )}
      {useMts && (
        <ToolTip align="top" tip={t('trim-video')}>
          <StyledButticon
            title={t('trim')}
            icon="scissors"
            onClick={onTrim}
            disabled={recording.isProcessing}
            size="tiny"
            data-testid="trim-icon-btn"
            isOutlined
          />
        </ToolTip>
      )}
      {transcriptsEnabled && (
        <ToolTip align="top" tip={t('view-transcript')}>
          <StyledButticon
            title={t('view-transcript')}
            icon="text-left"
            onClick={onViewTranscript}
            size="tiny"
            data-testid="view-transcript-icon-btn"
            isOutlined
          />
        </ToolTip>
      )}
      <ToolTip align="top" tip={t('download-recording')}>
        <StyledButticon
          title={t('download')}
          icon="download"
          href={downloadUrl}
          target="_blank"
          onClick={onDownload}
          size="tiny"
          disabled={recording.isProcessing}
          download
          data-testid="download-icon-btn"
          isOutlined
        />
      </ToolTip>
      <ToolTip align="top" tip={t('more-options')}>
        <StyledButticon
          title={t('more-options')}
          icon="option"
          onClick={() => setMenuOpen(!isMenuOpen)}
          size="tiny"
          data-testid="options-icon-btn"
          isOutlined
        />
      </ToolTip>

      <Menu
        isShowing={isMenuOpen}
        isDismissible={true}
        isInline={true}
        onItemSelected={() => setMenuOpen(false)}
        onClose={() => setMenuOpen(false)}
      >
        <MenuContent py={1}>
          {useMts && (
            <StyledMenuSelectItem
              data-testid="revert-button"
              label={t('revert-trim')}
              px={1}
              py={1}
              mx={1}
              enableRevert={enableRevert}
              leadingIcon="undo"
              onClick={() => {
                if (!enableRevert) return;
                onRevertTrim();
              }}
            />
          )}
          <MenuSelect labelledById="menu">
            <MenuSelectItem
              data-testid="delete-button"
              label={t('delete')}
              px={1}
              py={1}
              mx={1}
              leadingIcon="delete"
              onClick={onDelete}
            />
          </MenuSelect>
        </MenuContent>
      </Menu>
    </RecordingButtonsContainer>
  );
};

RecordingButtons.propTypes = {
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onRevertTrim: PropTypes.func,
  onTrim: PropTypes.func,
  recording: PropTypes.shape({
    playableUrl: PropTypes.string,
    isProcessing: PropTypes.bool,
    lastTrimmedTime: PropTypes.string,
  }),
  customThumbnailsEnabled: PropTypes.bool,
  onChangeThumbnail: PropTypes.func,
  downloadUrl: PropTypes.string.isRequired,
  transcriptsEnabled: PropTypes.bool,
  onViewTranscript: PropTypes.func,
  useMts: PropTypes.bool,
};
